import {
  setAudioPreference,
  setLivePosition,
  setPausePosition,
  setSubPreference,
  setSuspensionShow,
  setTerm,
} from "actions/ui";
import PlayerVolume from "components/Icons/PlayerVolume";
import PlayerBingeOverlay from "components/PlayerBingeOverlay";
import PlayerErrorMessage from "components/PlayerErrorMessage";
import PlayerOverlay from "components/PlayerOverlay";
import PlayerOverlayLive from "components/PlayerOverlayLive";
import consts from "consts/consts";
import { sendAnalytics } from "helpers/analytics";
import { getFromLocal, saveInLocal } from "helpers/localStorage";
import { parseQuery } from "helpers/url";
import { redirectTo } from "helpers/utility";
import jwt_decode from "jwt-decode";
import get from "lodash.get";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import screenfull from "screenfull";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

const OVERLAY_DELAY = 6000;
// eslint-disable-next-line no-unused-vars
let errorTimeout;
let timer = new Date();
timer.setSeconds(timer.getSeconds() + consts.playerErrorDelayTimer);
class Player extends PureComponent {
  state = {
    isReady: false,
    isOverlayVisible: true,
    isBingeOverlayVisible: undefined,
    isPlaying: false,
    isAdditionalContentVisible: false,
    progress: 0,
    isFullscreen: false,
    volumeInfo: this.props.isTrailer
      ? getFromLocal(consts.storageKeys.playerTrailer)
      : getFromLocal(consts.storageKeys.player),
    hasError: false,
    tracks: {
      activeAudio: undefined,
      activeText: undefined,
    },
    duration: null,
    initTime: timer.getSeconds(),
    showLoader: false,
    navigatorOnline: true,
    numberOfTryToStartPlayer: 5,
    isMuted:
      getFromLocal(consts.storageKeys.playerTrailer)?.volume === 0
        ? true
        : false,
    checkForSeek: true,
    seek: null,
    showPauseIcon: false,
    showSkipIntroButton: false,
    skipIntroPosition: null,
  };

  constructor(args) {
    super(args);
    this.player = undefined;
    this.videoContainer = React.createRef();
    this.playerContainer = React.createRef(); // This is used to OPEN player fullscreeen with UI
    this.overlayTimer = undefined;
    this.progressTimer = undefined;
    this.continueWatchingTimer = undefined;
    this.simulateClick = React.createRef();
    if (args.isLive) {
      this.canPause = get(consts, "playerLive.canPause", true); // pausable
      this.canSeekBackward = get(consts, "playerLive.canSeekBackward", true); // -15s
      this.canSeekForward = get(consts, "playerLive.canSeekForward", true); // +15s
      this.canSeekProgress = get(consts, "playerLive.canSeekProgress", true);
      this.canStartover = get(consts, "playerLive.canStartover", true); // Startover
      this.canRecord = get(consts, "playerLive.canRecord", false); // Enregistrement
      this.audio = get(consts, "playerLive.audio", {}); // audio
      this.quality = get(consts, "playerLive.quality", {}); // Qualité
      this.canPictureInPicture = get(consts, "canPictureInPicture", true); // Picture in Picture
    } else {
      this.canPause = get(consts, "player.canPause", true); // pausable
      this.canSeekBackward = get(consts, "player.canSeekBackward", true); // -15s
      this.canSeekForward = get(consts, "player.canSeekForward", true); // +15s
      this.canSeekProgress = get(consts, "player.canSeekProgress", true);
      this.audio = get(consts, "player.audio", {}); // audio
      this.quality = get(consts, "player.quality", {}); // Qualité
      this.canPictureInPicture = get(
        consts,
        "player.canPictureInPicture",
        true
      ); // Picture in Picture
    }

    this.handleKeyPressed = this.handleKeyPressed.bind(this);
    this.handleFullscreenChange = this.handleFullscreenChange.bind(this);
    this.handleclickonback = this.handleclickonback.bind(this);
  }

  addDocumentListeners() {
    document.addEventListener(
      "webkitfullscreenchange",
      this.handleFullscreenChange,
      false
    );
    document.addEventListener(
      "mozfullscreenchange",
      this.handleFullscreenChange,
      false
    );
    document.addEventListener(
      "msfullscreenchange",
      this.handleFullscreenChange,
      false
    );
    document.addEventListener(
      "MSFullscreenChange",
      this.handleFullscreenChange,
      false
    ); //IE11
    document.addEventListener(
      "fullscreenchange",
      this.handleFullscreenChange,
      false
    );
    document.addEventListener(
      "fullscreenerror",
      this.handleFullscreenChange,
      false
    );
    document.addEventListener("resize", this.handleFullscreenChange, false);
    document.addEventListener(
      "orientationchange",
      this.handleFullscreenChange,
      false
    );

    document.addEventListener("keydown", this.handleKeyPressed, false);
  }

  async handleShowOfTracks() {
    const { assetInfo, profileToken } = this.props;
    const decoded = profileToken ? jwt_decode(profileToken) : undefined;
    const { volumeInfo } = this.state;
    const player = this.instance;

    const tracks = player.getTracks();
    //init audio track
    const languageId = getFromLocal("languageId");
    // eslint-disable-next-line no-unused-vars
    const currentLanguge = consts.languageIdMapping[languageId];

    let oldIndex = tracks.audio.findIndex((element) => element["is-active"]);
    // Check by language name
    let newIndex = tracks.audio.findIndex(
      (element) => element.language === this.props.audioLanguageRef
    );
    //chack by language reference if the saved language name doesn't match any audio
    if (newIndex === -1) {
      newIndex = tracks.audio.findIndex(
        (element) => element.reference === this.props.audioPreferenceRef
      );
    }
    if (newIndex !== -1) {
      if (oldIndex !== -1) tracks.audio[oldIndex]["is-active"] = false;
      await this.instance.toggleTrack(tracks.audio[newIndex], true);
      this.props.dispatch(
        setAudioPreference({
          audio: tracks.audio[newIndex],
          profileId: decoded ? decoded.id_profile : undefined,
        })
      );
      tracks.audio[newIndex]["is-active"] = true;
      tracks.activeAudio = newIndex;
    } else {
      if (!this.props.isLive) {
        let returnedAudio = assetInfo?.lang?.filter((langObject) => {
          return langObject.audio === languageId;
        });

        if (returnedAudio && returnedAudio.length > 0) {
          let langIndex = tracks.audio.findIndex((audios) => {
            return (
              audios.name == consts.languageIdMapping[returnedAudio[0].audio]
            );
          });
          if (langIndex !== -1) {
            tracks.audio = tracks.audio.map((audios, index) => {
              if (langIndex !== index) {
                audios["is-active"] = false;
              }
              return audios;
            });
            await this.instance.toggleTrack(tracks.audio[langIndex], true);
            this.props.dispatch(
              setAudioPreference({
                audio: tracks.audio[langIndex],
                profileId: decoded ? decoded.id_profile : undefined,
              })
            );
            tracks.audio[langIndex]["is-active"] = true;
            tracks.activeAudio = langIndex;
          }
        }
      } else {
        let currentLangugeAudioIndex = tracks.audio.findIndex(
          (element) =>
            element.language === currentLanguge ||
            element.name === currentLanguge
        );
        if (currentLangugeAudioIndex !== -1) {
          tracks.audio = tracks.audio.map((audios, index) => {
            if (currentLangugeAudioIndex !== index) {
              audios["is-active"] = false;
            }
            return audios;
          });
          await player.toggleTrack(
            tracks.audio[currentLangugeAudioIndex],
            true
          );
          this.props.dispatch(
            setAudioPreference({
              audio: tracks.audio[currentLangugeAudioIndex],
              profileId: decoded ? decoded.id_profile : undefined,
            })
          );
          tracks.audio[currentLangugeAudioIndex]["is-active"] = true;
          tracks.activeAudio = currentLangugeAudioIndex;
        }
      }
      // tracks.activeAudio = undefined;
    }

    oldIndex = tracks.text.findIndex((element) => element["is-active"]);
    // Check by language name
    newIndex = tracks.text.findIndex(
      (element) => element.language === this.props.subtitleLanguageRef
    );
    //chack by language reference if the saved language name doesn't match any audio
    if (newIndex === -1) {
      newIndex = tracks.text.findIndex(
        (element) => element.reference === this.props.subtitlePreferenceRef
      );
    }
    if (newIndex !== -1) {
      if (oldIndex !== -1) tracks.text[oldIndex]["is-active"] = false;
      await this.instance.toggleTrack(tracks.text[newIndex], true);
      this.props.dispatch(
        setSubPreference({
          sub: tracks.text[newIndex],
          profileId: decoded ? decoded.id_profile : undefined,
        })
      );
      tracks.text[newIndex]["is-active"] = true;
      tracks.activeText = newIndex;
    } else {
      tracks.activeText = undefined;
    }

    if (volumeInfo) {
      player.setVolume(volumeInfo);
    }

    this.setState({
      tracks,
    });
    await this.instance.applyTracks();
  }

  async addPlayerListeners() {
    const { history, asset, assetInfo, onTrailerEnds } = this.props;

    this.instance.addEventListener("playing", async (e) => {
      if (e.reason === "firstPlay") {
        if (
          this.state.tracks.activeAudio === undefined &&
          this.state.tracks.activeText === undefined
        ) {
          await this.handleShowOfTracks();
        }
        this.setState({
          isPlaying: true,
        });
      }
    });

    this.instance.addEventListener("status", async (e) => {
      if (e.status === "ready") {
        await this.handleShowOfTracks();
        // Player is ready
      } else if (e.status === "error") {
        if (!navigator.onLine) {
          this.setState({ showLoader: true, navigatorOnline: false });
          this.errorTimeout = setTimeout(() => {
            if (!navigator.onLine) this.setState({ hasError: true });
          }, (consts.playerErrorDelayTimer + 10) * 1000);
        }
        // TODO I don't see the use iof those lines
        // else {
        //   this.setState({ hasError: true });
        // }
        if (this.props.isConnected) {
          if (this.props.isLive) {
            if (this.instance.playing) {
              sendAnalytics(
                this.props.dispatch,
                "FATAL",
                "51",
                "LINEAR",
                JSON.stringify({
                  channelId: assetInfo.channel.id,
                  reason: {},
                })
              );
            } else {
              sendAnalytics(
                this.props.dispatch,
                "FATAL",
                "52",
                "LINEAR",
                JSON.stringify({
                  channelId: assetInfo.channel.id,
                  reason: {},
                })
              );
            }
          } else {
            if (this.instance.playing) {
              sendAnalytics(
                this.props.dispatch,
                "FATAL",
                "1051",
                "VOD+CATCHUP",
                JSON.stringify({
                  assetId: assetInfo.assetId,
                  reason: {},
                })
              );
            } else {
              sendAnalytics(
                this.props.dispatch,
                "FATAL",
                "1052",
                "VOD+CATCHUP",
                JSON.stringify({
                  assetId: assetInfo.assetId,
                  reason: {},
                })
              );
            }
          }
        }
      } else if (e.status === "ended") {
        this.handleClickOnProgressBar(0, true);
        if (onTrailerEnds) {
          onTrailerEnds();
        } else if (asset) {
          let url = consts.routes.movieAsset.url.replace(":id", asset.getId());
          if (asset.isAssetTVShowEpisode && !asset.isCatchupAsset) {
            url = consts.routes.tvshowAsset.url.replace(
              ":id",
              asset.getParentId()
            );
          }
          history.replace(url);
        }
      }
    });
  }

  async destroyPlayer(player) {
    await this.playerLeaveAnalytics();

    try {
      player.removeEventListener("status");
      player.destroy();
      this.session?.stopStreamingSession();
    } catch (_error) {
      // console.error(error);
    }
  }

  async getPlayerInstance() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (accept, _reject) => {
      const vip = window.vip;
      // eslint-disable-next-line no-unused-vars
      const concentList = getFromLocal("concentList");
      if (this.instance !== undefined && this.instance !== null) {
        accept(this.instance);
        return;
      }
      let setup;
      if (this.props.isLive) {
        setup = {
          app_key: consts.veygoKey,
          activate_veygo_analytics: consts.veygoAnalytics,
          device_id: await vip.Utils.getHash(
            getFromLocal(consts.storageKeys.device)
          ),
          app: {
            name: `VeygoWebDemoPlayer`,
            version: "version-lol",
          },
        };
      } else {
        setup = {
          app_key: consts.veygoKey,
          activate_veygo_analytics: consts.veygoKey,
          device_id: await vip.Utils.getHash(
            getFromLocal(consts.storageKeys.device)
          ),
          "abr.initial_bandwidth_estimate": 3000000,
          app: {
            name: `VeygoWebDemoPlayer`,
            version: "version-lol",
          },
        };
      }
      const { player } = await vip.setup(
        setup,
        "default user id",
        this.videoContainer.current
      );
      if (!this.props.isLive) {
        await player.setup({
          "abr.initial_bandwidth_estimate": 3000000,
        });
      }
      // player.start();

      window.player = player;
      this.instance = player;
      accept(player);
    });
  }

  getSource() {
    const isSafari = getFromLocal("isSafari");
    let licParam = get(this.props.drm, "licParam", undefined);
    const source = {
      uri: this.props.url,
      type: this.props.url?.indexOf(".m3u8") !== -1 ? "hls" : "dash",
    };
    let provider = this.props.drm?.vendor?.toLowerCase() || consts.providerDrm;
    source["content_type"] = "noType";
    if (provider === "castlabs" || isSafari) {
      source["content_type"] = "vod";
      if (this.props.drm?.url?.includes("vudrm.tech")) {
        provider = "vualto";
      }
    }
    if (licParam) {
      let drmToken = undefined;
      let serverCertificate = undefined;
      if (provider === "castlabs" || provider === "drmtoday") {
        if (typeof licParam === "object") {
          drmToken = licParam?.sessionToken;
          serverCertificate = licParam?.serverCertificateURL;
        } else {
          drmToken =
            typeof licParam !== "object"
              ? parseQuery(licParam).token
              : licParam.token;
          serverCertificate =
            typeof licParam !== "object"
              ? parseQuery(licParam).serverCertificateURL
              : licParam.serverCertificateURL;
        }
      } else if (provider === "irdeto") {
        drmToken = this.props.drm.licParam.sessionToken;
        serverCertificate = licParam.serverCertificateURL;
      } else if (provider === "vualto") {
        drmToken = licParam.sessionToken || licParam.token;
        serverCertificate = licParam?.serverCertificateURL;
      }

      if (drmToken) {
        const drmType = isSafari ? "fairplay" : "widevine";
        source[`${drmType}.license_server`] = this.props.drm?.url;
        source[`${drmType}.certificate_uri`] = serverCertificate;
        source[`drm.token`] = drmToken;
        switch (provider) {
          case "castlabs":
          case "drmtoday":
            source[`${drmType}.auth_method`] = "drmtoday";
            break;
          case "irdeto":
            source[`${drmType}.auth_method`] = "irdeto";
            break;
          case "vualto":
            {
              source[`${drmType}.auth_method`] = "vualto";
              source[`${drmType}.license_server`] = `${
                this.props.drm?.url
              }?token=${encodeURIComponent(drmToken)}`;
            }
            break;
          default:
            throw new Error("unsupportedDrmProvider");
        }
      }
    }
    return source;
  }

  async handleClickOnPlayPause() {
    const { assetInfo } = this.props;
    const concentList = getFromLocal("concentList");

    let progress;
    if (this.instance !== undefined && this.instance !== null) {
      progress = await this.instance.getTimeInfo();
      if (this.state.isPlaying === true) {
        await this.instance.pause();
        // await this.sendSeekAsset(); // I deleted this call because they trigger another sendAnalytics function call
        await this.setState({ isPlaying: false });
        // await clearTimeout(this.progressTimer);
        await clearTimeout(this.continueWatchingTimer);
        if (this.props.isConnected && concentList?.analytics?.isActive) {
          if (this.props.isLive) {
            // LINEAR stop
            sendAnalytics(
              this.props.dispatch,
              "INFO",
              "7",
              "LINEAR",
              JSON.stringify({
                channelId: assetInfo.channel.id,
                position: progress ? progress.position : 0,
              })
            );
          } else {
            await this.instance.pause();
            // await this.sendSeekAsset(); // I deleted this call because they trigger another sendAnalytics function call
            await this.setState({ isPlaying: false });
            // await clearTimeout(this.progressTimer);
            await clearTimeout(this.continueWatchingTimer);
            // VOD pause
            sendAnalytics(
              this.props.dispatch,
              "INFO",
              "1006",
              "VOD+CATCHUP",
              JSON.stringify({
                assetId: assetInfo.assetId,
                position: progress ? progress.position / 1000 : 0,
              })
            );
          }
        }
      } else {
        this.instance.play();
        this.setState({ isPlaying: true });
        this.updateProgress();
        this.postContinueWatchingEvent();
        if (this.props.isConnected && concentList?.analytics?.isActive) {
          if (this.props.isLive) {
            // LINEAR resume
            sendAnalytics(
              this.props.dispatch,
              "INFO",
              "8",
              "LINEAR",
              JSON.stringify({
                channelId: assetInfo.channel.id,
                position: progress ? progress.position / 1000 : 0,
              })
            );
          } else {
            // VOD resume
            sendAnalytics(
              this.props.dispatch,
              "INFO",
              "1008",
              "VOD+CATCHUP",
              JSON.stringify({
                assetId: assetInfo.assetId,
                position: progress ? progress.position / 1000 : 0,
              })
            );
          }
        }
      }
    }
  }

  async handleClickOnProgressBarLive(
    percentage,
    submitSeek,
    current,
    callback
  ) {
    //The setInterval is just to keep checking if the player instance is ready then apply the seekTo
    const interval = setInterval(async () => {
      const { assetInfo } = this.props;
      const startTime = assetInfo.startTime.getTime();
      const endTime = assetInfo.endTime.getTime();
      const programDuration = endTime - startTime;
      const getTimeInfo = await this.instance.getTimeInfo();
      if (this.instance && getTimeInfo) {
        clearInterval(interval);
        const seekPosition = (programDuration * percentage) / 100;
        if (!isNaN(seekPosition) && percentage <= current) {
          await this.instance.seekTo(seekPosition + startTime);
        }
        if (!this.state.isPlaying) {
          this.updateProgress();
        }

        if (callback) {
          callback();
        }
      }
    }, 500);
  }

  handleCloseRemote = () => {
    this.setState({ isAdditionalContentVisible: false });
    this.props.dispatch(setTerm(""));
  };

  async handleClickOnRemote() {
    const isAdditionalContentVisible = this.state.isAdditionalContentVisible;
    const { assetInfo } = this.props;
    let progress;
    if (this.instance !== undefined && this.instance !== null) {
      progress = await this.instance.getTimeInfo();
      this.setState({
        isAdditionalContentVisible: !isAdditionalContentVisible,
      });
      // saveInLocal("displaycontrols", isAdditionalContentVisible);
      if (!isAdditionalContentVisible) {
        clearTimeout(this.overlayTimer);
      } else {
        this.overlayTimer = setTimeout(() => {
          if (this.state.isPlaying) {
            this.setState({ isOverlayVisible: false });
          }
        }, OVERLAY_DELAY);
      }
      if (this.props.isConnected) {
        if (this.props.isLive) {
          // LINEAR stop on context change
          sendAnalytics(
            this.props.dispatch,
            "INFO",
            "53",
            "LINEAR",
            JSON.stringify({
              channelId: assetInfo.channel.id,
              position: progress ? progress.position / 1000 : 0,
            })
          );
        } else {
          // VOD stop on context change
          sendAnalytics(
            this.props.dispatch,
            "INFO",
            "1053",
            "VOD+CATCHUP",
            JSON.stringify({
              assetId: assetInfo.assetId,
              position: progress ? progress.position / 1000 : 0,
            })
          );
        }
      }
    }
  }

  async handleClickOnSeekBackward() {
    const { progress } = this.state;
    const newPosition = progress.position - 15000;
    if (!isNaN(newPosition)) {
      const seekedPosition = newPosition < 0 ? 0 : newPosition;
      await this.instance.seekTo(newPosition < 0 ? 0 : seekedPosition);
    }

    if (!this.state.isPlaying) {
      this.updateProgress();
    }
    this.setOverlayTimer();
  }

  async handleClickOnSeekForward() {
    const { progress } = this.state;
    const newPosition = progress.position + 15000;
    await this.instance.seekTo(newPosition);

    if (!this.state.isPlaying) {
      this.updateProgress();
    }
    this.setOverlayTimer();
  }

  handleCloseBingeWatching() {
    this.setState({ isBingeOverlayVisible: false });
  }

  goNextEpisode() {
    const { history, nextEpisode } = this.props;
    this.setState({ isBingeOverlayVisible: undefined });
    clearTimeout(this.progressTimer);
    history.replace(
      `${consts.routes.player.url.replace(
        ":assetId",
        nextEpisode.getId()
      )}?restart=true`
    );
  }

  handleFullscreenChange() {
    this.setState({ isFullscreen: !this.state.isFullscreen });
  }

  async handleKeyPressed(event) {
    if (this.instance !== undefined && this.instance !== null) {
      const progress = await this.instance.getTimeInfo();
      const { assetInfo, isLive, timeshift } = this.props;
      // Space
      if (
        event.keyCode === 32 &&
        (!isLive || (this.canPause && timeshift && timeshift > 0))
      ) {
        if (this.state.isPlaying) {
          this.setState({ isPlaying: false });
          this.instance.pause();
          if (progress !== null && this.props.isConnected) {
            if (this.props.isLive) {
              // LINEAR stop
              sendAnalytics(
                this.props.dispatch,
                "INFO",
                "7",
                "LINEAR",
                JSON.stringify({
                  channelId: assetInfo.channel.id,
                  position: progress ? progress.position / 1000 : 0,
                })
              );
            } else {
              // VOD pause
              sendAnalytics(
                this.props.dispatch,
                "INFO",
                "1006",
                "VOD+CATCHUP",
                JSON.stringify({
                  assetId: assetInfo.assetId,
                  position: progress ? progress.position / 1000 : 0,
                  unit: "seconds",
                })
              );
            }
          }
        } else {
          this.setState({ isPlaying: true });
          this.instance.play();
          if (progress !== null && this.props.isConnected) {
            if (this.props.isLive) {
              // LINEAR resume
              sendAnalytics(
                this.props.dispatch,
                "INFO",
                "8",
                "LINEAR",
                JSON.stringify({
                  channelId: assetInfo.channel.id,
                  position: progress ? progress.position / 1000 : 0,
                })
              );
            } else {
              // VOD resume
              sendAnalytics(
                this.props.dispatch,
                "INFO",
                "1008",
                "VOD+CATCHUP",
                JSON.stringify({
                  assetId: assetInfo.assetId,
                  position: progress ? progress.position / 1000 : 0,
                  unit: "seconds",
                })
              );
            }
          }
        }
      }

      // F11 key
      if (event.keyCode === 122) {
        event.preventDefault();
        this.handleClickOnFullscreen();
      }
    }
  }

  handleMouseMove() {
    if (this.state.isAdditionalContentVisible) {
      return;
    }
    this.setState({ isOverlayVisible: true });

    this.setOverlayTimer();
  }

  async handleTrackChange(type, index) {
    const { tracks } = this.state;
    const { assetInfo, profileToken } = this.props;
    const decoded = jwt_decode(profileToken);

    if (type === "audio") {
      if (tracks && tracks.audio) {
        if (index === undefined) {
          if (tracks.audio[0]) {
            await this.instance.toggleTrack(tracks.audio[0], false);
            this.props.dispatch(
              setAudioPreference({
                audio: tracks.audio[0],
                profileId: decoded.id_profile,
              })
            );
            if (this.props.isLive && this.instance && this.props.isConnected) {
              sendAnalytics(
                this.props.dispatch,
                "INFO",
                "101",
                "LINEAR",
                JSON.stringify({
                  channelId: assetInfo.channel.id,
                  origin: 4,
                  audioLanguage: tracks.audio[0],
                  subtitleLanguage: tracks.activeText
                    ? tracks.text[tracks.activeText]
                    : null,
                })
              );
            }
          }
        } else if (tracks.audio[index]) {
          await this.instance.toggleTrack(tracks.audio[index], true);
          this.props.dispatch(
            setAudioPreference({
              audio: tracks.audio[index],
              profileId: decoded.id_profile,
            })
          );
          if (this.props.isLive && this.instance && this.props.isConnected) {
            sendAnalytics(
              this.props.dispatch,
              "INFO",
              "101",
              "LINEAR",
              JSON.stringify({
                channelId: assetInfo.channel.id,
                origin: 3,
                audioLanguage: tracks.audio[index],
                subtitleLanguage: tracks.activeText
                  ? tracks.text[tracks.activeText]
                  : null,
              })
            );
          }
        }
        let oldIndex = tracks.audio.findIndex(
          (element) => element["is-active"]
        );
        if (oldIndex !== -1) tracks.audio[oldIndex]["is-active"] = false;
        if (index !== undefined) tracks.audio[index]["is-active"] = true;
        tracks.activeAudio = index;
      }
    }
    if (type === "text") {
      if (tracks && tracks.text) {
        if (index === undefined) {
          if (tracks && tracks.text && tracks.text[0]) {
            await this.instance.toggleTrack(tracks.text[0], false);
            this.props.dispatch(
              setSubPreference({
                sub: undefined,
                profileId: decoded.id_profile,
              })
            );
            if (this.props.isLive && this.instance && this.props.isConnected) {
              sendAnalytics(
                this.props.dispatch,
                "INFO",
                "101",
                "LINEAR",
                JSON.stringify({
                  channelId: assetInfo.channel.id,
                  origin: 2,
                  audioLanguage: tracks.activeAudio
                    ? tracks.text[tracks.activeAudio]
                    : null,
                  subtitleLanguage: tracks.text[0],
                })
              );
            }
          }
        } else if (tracks.text[index]) {
          await this.instance.toggleTrack(tracks.text[index], true);
          this.props.dispatch(
            setSubPreference({
              sub: tracks.text[index],
              profileId: decoded.id_profile,
            })
          );
          if (this.props.isLive && this.instance && this.props.isConnected) {
            sendAnalytics(
              this.props.dispatch,
              "INFO",
              "101",
              "LINEAR",
              JSON.stringify({
                channelId: assetInfo.channel.id,
                origin: 1,
                audioLanguage: tracks.activeAudio
                  ? tracks.text[tracks.activeAudio]
                  : null,
                subtitleLanguage: tracks.text[index],
              })
            );
          }
        }
        tracks.activeText = index;
        let oldIndex = tracks.text.findIndex((element) => element["is-active"]);
        if (oldIndex !== -1) tracks.text[oldIndex]["is-active"] = false;
        if (index !== undefined) tracks.text[index]["is-active"] = true;
      }
    }
    this.setState({ tracks });
    await this?.instance?.applyTracks();
  }

  handleVolumeChange(volume) {
    const isAdditionalContentVisible = this.state.isAdditionalContentVisible;
    if (isAdditionalContentVisible) {
      this.handleCloseRemote();
    }
    this.props.isTrailer
      ? saveInLocal(consts.storageKeys.playerTrailer, volume)
      : saveInLocal(consts.storageKeys.player, volume);
    this.setState({ volumeInfo: volume });
    this.instance.setVolume(volume);
  }

  switchVolume(event) {
    event.stopPropagation();
    event.preventDefault();
    const { isMuted } = this.state;
    const volume = {
      volume: isMuted ? 1 : 0,
      muted: !isMuted,
    };
    saveInLocal(consts.storageKeys.playerTrailer, volume);
    this.instance.setVolume(volume);
    this.setState({
      volumeInfo: isMuted,
      isMuted: !isMuted,
    });
  }

  async handleClickOnProgressBar(percentage, _submitSeek, callback) {
    if (this.instance) {
      const pos = await this.instance.getTimeInfo();
      const seekPosition = (await ((pos?.duration || 0) * percentage)) / 100;
      if (!isNaN(seekPosition)) {
        const seekedPosition = seekPosition < 1000 ? 0 : seekPosition;
        await this.instance.seekTo(seekedPosition);
      }

      if (!this.state.isPlaying) {
        this.updateProgress();
      }

      if (callback) {
        callback();
      }
    }
  }

  handleClickOnFullscreen() {
    document.querySelector("#container").style.paddingBottom = "0";
    if (
      document.fullscreen ||
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      const playerRef = this.playerContainer.current;
      if (playerRef) {
        screenfull.toggle();
      }
    }
  }

  initializePlayer() {
    const { assetInfo } = this.props;
    this.setState({ isReady: true });
    this.updateProgress();
    this.postContinueWatchingEvent();
    if (this.props.isLive) {
      const source = this.getSource();
      let drmName = null;
      let videoType;
      videoType = source.type;
      const videoTypeCode = consts.videoTypes[videoType];
      if (videoTypeCode === 5) {
        drmName = "widevine";
      }
      if (videoTypeCode === 2) {
        drmName = "fairplay";
      }
      if (this.props.isConnected) {
        // LINEAR start
        sendAnalytics(
          this.props.dispatch,
          "INFO",
          "2",
          "LINEAR",
          JSON.stringify({
            channelId: assetInfo.channel.id,
            audioLanguage: null,
            subtitleLanguage: null,
            audioCodec: null,
            videoCodec: null,
            technology: videoType,
            drm: consts.drmNames[drmName],
          })
        );
      }
    } else {
      // VOD start
      if (this.props.isConnected)
        sendAnalytics(
          this.props.dispatch,
          "INFO",
          "1002",
          "VOD+CATCHUP",
          JSON.stringify({ assetId: assetInfo.assetId })
        );
    }
  }

  async playerLeaveAnalytics() {
    const { assetInfo } = this.props;
    let progress;
    if (this.instance !== undefined && this.instance !== null) {
      progress = await this.instance.getTimeInfo();
      if (this.props.isConnected) {
        if (this.props.isLive) {
          // LINEAR stop
          if (assetInfo && assetInfo.channel) {
            sendAnalytics(
              this.props.dispatch,
              "INFO",
              "7",
              "LINEAR",
              JSON.stringify({
                channelId: assetInfo.channel.id,
                position: progress ? progress.position / 1000 : 0,
              })
            );
          }
        } else {
          // VOD stop
          sendAnalytics(
            this.props.dispatch,
            "INFO",
            "1007",
            "VOD+CATCHUP",
            JSON.stringify({
              assetId: assetInfo.assetId,
              position: progress ? progress.position / 1000 : 0,
              unit: "seconds",
            })
          );
        }
      }
    }
  }

  postContinueWatchingEvent() {
    this.continueWatchingTimer = setTimeout(() => {
      this.postContinueWatchingEvent();
    }, 30000);
  }

  async sendSeekAsset() {
    const { assetInfo } = this.props;
    if (this.instance && this.props.postVideoProgress) {
      const progress = await this.instance.getTimeInfo();
      if (progress) {
        this.props.postVideoProgress(Math.floor(progress.position / 1000));
      }
    }
    if (this.instance) {
      const progress = await this.instance.getTimeInfo();
      // if (this.props.isLive) {
      //   //linear position
      //   sendAnalytics(
      //     this.props.dispatch,
      //     "INFO",
      //     "4",
      //     "LINEAR",
      //     JSON.stringify({
      //       channelId: assetInfo.channel.id,
      //       livePosition: progress ? progress.position / 1000 : 0,
      //       bufferStartPosition: null,
      //       playbackPosition: null,
      //     })
      //   );
      // } else {
      // vod position
      if (!this.props.isLive && this.props.isConnected) {
        sendAnalytics(
          this.props.dispatch,
          "INFO",
          "1004",
          "VOD+CATCHUP",
          JSON.stringify({
            assetId: assetInfo.assetId,
            position: progress ? progress.position / 1000 : 0,
            unit: "seconds",
          })
        );
      }
    }
  }

  setupAnalytics() {
    const { assetInfo } = this.props;
    if (this.props.isConnected) {
      if (this.props.isLive) {
        // LINEAR Load
        if (assetInfo && assetInfo?.channel?.id) {
          sendAnalytics(
            this.props.dispatch,
            "INFO",
            "1",
            "LINEAR",
            JSON.stringify({
              channelId: assetInfo.channel.id,
              throughNanoCdn: null,
              frequency: null,
            })
          );
        }
      } else {
        // VOD Load
        sendAnalytics(
          this.props.dispatch,
          "INFO",
          "1001",
          "VOD+CATCHUP",
          JSON.stringify({
            assetId: assetInfo.assetId,
            from: this.props.previousRoute || null,
          })
        );
      }
    }

    this.timer = setInterval(async () => {
      if (this.instance !== undefined && this.instance !== null) {
        const progress = await this.instance.getTimeInfo();
        if (this.state.isPlaying === true && assetInfo) {
          if (navigator.onLine) {
            clearTimeout(this.errorTimeout);
            this.setState({ hasError: false, navigatorOnline: true });
          }

          if (this.props.isConnected) {
            if (this.props.isLive && assetInfo?.channel?.id) {
              //LINEAR position
              sendAnalytics(
                this.props.dispatch,
                "INFO",
                "4",
                "LINEAR",
                JSON.stringify({
                  channelId: assetInfo.channel.id,
                  livePosition: progress ? progress.position / 1000 : 0,
                  bufferStartPosition: null,
                  playbackPosition: null,
                })
              );
            } else {
              // VOD position
              sendAnalytics(
                this.props.dispatch,
                "INFO",
                "1004",
                "VOD+CATCHUP",
                JSON.stringify({
                  assetId: assetInfo.assetId,
                  position: progress ? progress.position / 1000 : 0,
                  unit: "seconds",
                })
              );
            }
          }
        }
      }
    }, 30000);
  }

  startPlayer = async () => {
    const { duration, initTime, isLive } = this.state;
    const { assetInfo } = this.props;
    const source = this.getSource();
    const player = this.instance;
    const options = {};

    if (!isLive) {
      options.startPosition = this.props.assetInfo.seek * 1000 || 0;
    }

    await player
      .setSource(source, options)
      .then(async () => {
        this.initializePlayer();

        player
          .play()
          .then(() => {
            this.setState({
              isPlaying: true,
              duration: new Date().getSeconds(),
              hasError: false,
            });
          })
          .catch((_e) => {
            this.setState({
              showPauseIcon: true,
              isPlaying: false,
              duration: new Date().getSeconds(),
              hasError: false,
            });
          });
      })
      .catch(async (error) => {
        this.setState({ duration: new Date().getSeconds() });
        if (
          (duration === null || (duration < initTime && navigator.onLine)) &&
          this.state.numberOfTryToStartPlayer > 0
        ) {
          await this.setState(
            (prevState, _props) => {
              return {
                numberOfTryToStartPlayer:
                  prevState.numberOfTryToStartPlayer - 1,
              };
            },
            async () => {
              await this.startPlayer();
            }
          );
        } else {
          this.setState({ hasError: true });
          if (assetInfo && this.props.isConnected) {
            if (this.props.isLive) {
              //LINEAR initial load error
              sendAnalytics(
                this.props.dispatch,
                "ERROR",
                "50",
                "LINEAR",
                JSON.stringify({
                  channelId: assetInfo.channel?.id,
                  reason: { httpCode: error },
                })
              );
            } else {
              // VOD initial load error
              sendAnalytics(
                this.props.dispatch,
                "ERROR",
                "1050",
                "VOD+CATCHUP",
                JSON.stringify({
                  assetId: assetInfo.assetId,
                  reason: { httpCode: error },
                })
              );
            }
          }
        }
      });
  };
  subscriptionModal(asset) {
    const { accountStatus, dispatch } = this.props;

    if (accountStatus && accountStatus.toLowerCase() === "suspended") {
      dispatch(setSuspensionShow());
    } else if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  async updateProgress() {
    const { dispatch } = this.props;
    const progress = await this.instance?.getTimeInfo();
    if (navigator.onLine) {
      clearTimeout(this.errorTimeout);
    }
    if (this.progressTimer) {
      clearTimeout(this.progressTimer);
    }
    if (this.instance !== undefined && this.instance !== null) {
      let that = this;
      this.progressTimer = setTimeout(async () => {
        if (progress && this.state.progress?.position !== progress.position) {
          if (this.state.isPlaying && this.state.showPauseIcon) {
            this.setState({
              showPauseIcon: false,
            });
          }
          if (
            (this.props.isTrailerPage || this.props.isTrailer) &&
            progress.duration - progress.position <= 1000
          ) {
            dispatch(TucanoActions.setTrailerStatus(false));
          }
          // close player in case of the end of tvshow and back to details page
          that.checkEndOfTvShow(progress);
          that.checkShowBingeWatching(progress);
          this.checkShowSkipIntro(progress);
        }
        this.updateProgress();
      }, 200);
    }
  }

  checkShowSkipIntro(progress) {
    const { assetTags } = this.props;
    const { showSkipIntroButton } = this.state;
    if (assetTags?.Intro) {
      const shouldShowSkipIntro =
        progress &&
        (assetTags?.Intro.length === 1
          ? progress.position < assetTags?.Intro[0]
          : progress.position >= assetTags?.Intro[0] &&
            progress.position < assetTags?.Intro[1]);
      if (!shouldShowSkipIntro && showSkipIntroButton) {
        this.setState({
          showSkipIntroButton: false,
          skipIntroPosition: null,
        });
      } else if (shouldShowSkipIntro && !showSkipIntroButton) {
        this.setState({
          showSkipIntroButton: true,
          skipIntroPosition: assetTags.Intro.at(-1),
        });
      }
    }
  }

  onSkipIntroClick = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (this.instance) {
      const pos = await this.instance.getTimeInfo();
      if (pos) {
        await this.instance.seekTo(this.state.skipIntroPosition);
        this.updateProgress();
      }
    }
  };

  isLock(episode) {
    const { accountStatus } = this.props;
    if (accountStatus && accountStatus.toLowerCase() === "suspended")
      return true;
    if (!consts.padlocks) return false;
    if (episode.isCategory) return false;
    if (episode.isAssetTVShow && !episode.isAssetTVShowEpisode) return false;
    if (episode.getType() === "link") return false;
    if (!this.props.isConnected) return false;
    return !episode.userHasAccess;
  }

  checkShowBingeWatching(progress) {
    const { assetTags, isLive, nextEpisode } = this.props;
    const newState = { progress: progress };
    const shouldShowTheBinge =
      !isLive &&
      progress &&
      progress.position >=
        (assetTags?.Credits
          ? assetTags.Credits[0] || progress.duration - consts.offsetBingeValue
          : progress.duration - consts.offsetBingeValue) &&
      this.state.isBingeOverlayVisible === undefined &&
      typeof nextEpisode !== "undefined";
    if (shouldShowTheBinge && !this.isLock(nextEpisode)) {
      newState.isBingeOverlayVisible = true;
    }
    this.setState(newState);
  }

  checkEndOfTvShow(progress) {
    if (
      progress.duration - progress.position <= 1200 &&
      typeof this.props.nextEpisode === "undefined" &&
      this.props.assetInfo.isTVShowEpisode &&
      !this.props.asset.isCatchupAsset
    ) {
      this.cleanUpEvents();
      this.props.history.replace(
        consts.routes.tvshowAsset.url.replace(
          ":id",
          this.props.assetInfo.idParent
        )
      );
    }
  }

  async componentDidMount() {
    const { isTrailer, dispatch } = this.props;

    this.setState({ isBingeOverlayVisible: undefined });
    if (this.simulateClick && this.simulateClick.current) {
      if (this.props.isFullscreen || this.props.location.state?.fullScreen) {
        // this.simulateClick.current.click();
        this.handleFullscreenChange();
      }
    }
    this.unlistenForRouteChange = this.props.history.listen(
      (_location, _action) => {
        clearTimeout(this.progressTimer);
        this.setState({ isBingeOverlayVisible: undefined });
      }
    );
    await this.getPlayerInstance();
    await this.setupAnalytics();
    await this.addPlayerListeners();
    this.addDocumentListeners();
    this.startPlayer();
    // await this.props.dispatch(setNewPosition(null));
    await this.props.dispatch(setLivePosition(null));
    await this.props.dispatch(setPausePosition(null));
    if (isTrailer) {
      await dispatch(TucanoActions.setTrailerStatus(true));
      this.instance.setVolume({
        volume: 0,
        muted: true,
      });
    }
  }

  async componentDidUpdate(prevProps) {
    const { isTrailer, url, assetInfo, drm } = this.props;
    const { checkForSeek, isPlaying } = this.state;
    const progress = this.instance ? await this.instance.getTimeInfo() : null;
    const isSafari = getFromLocal("isSafari");
    const options = {
      startPosition: assetInfo.seek * 1000 || 0,
    };

    if (
      prevProps.drm !== drm &&
      isSafari &&
      isPlaying &&
      url.indexOf(".m3u8") !== -1
    ) {
      const source = this.getSource();
      if (this.instance) {
        this.instance.setSource(source, options);
        // force play
        setTimeout(async () => {
          await this.handleKeyPressed({ keyCode: 32 });
          setTimeout(async () => {
            const newState = {
              checkForSeek: true,
              checkIsPlaying: true,
            };
            await this.setState({ ...newState });
            await this.handleKeyPressed({ keyCode: 32 });
          }, 4000);
        }, 700);
      }
    }
    if (
      JSON.stringify(assetInfo) !== "{}" &&
      prevProps.assetInfo?.assetId !== assetInfo?.assetId
    ) {
      this.setState({ isBingeOverlayVisible: undefined });
    }
    if (prevProps.url !== url) {
      const source = this.getSource();
      if (this.instance) {
        this.instance.setSource(source, options);
      }
    }
    if (
      navigator.onLine &&
      !this.state.navigatorOnline &&
      this.state.showLoader
    ) {
      await clearTimeout(this.errorTimeout);
      await this.setState({
        showLoader: false,
        hasError: false,
        navigatorOnline: true,
      });
    }

    if (
      consts.player?.resetPlayerOnPercent &&
      !isTrailer &&
      checkForSeek &&
      progress
    ) {
      const progressPercent = (progress.position / progress.duration) * 100;
      const newState = {
        checkForSeek: true,
        checkIsPlaying: true,
      };
      if (progressPercent) newState.checkForSeek = false;
      if (progressPercent > consts.player.resetPlayerOnPercent) {
        if (this.instance) {
          const source = this.getSource();
          this.instance.setSource(source, {
            startPosition: 0,
          });
          setTimeout(async () => {
            await this.instance.play();
          }, 700);
        }
      }
      await this.setState({ ...newState });
    }
  }

  cleanUpEvents() {
    const { assetInfo } = this.props;
    const { tracks } = this.state;
    clearTimeout(this.continueWatchingTimer);
    clearTimeout(this.overlayTimer);
    clearTimeout(this.progressTimer);
    clearInterval(this.timer);
    this.unlistenForRouteChange();
    if (tracks?.activeAudio || tracks?.activeText) {
      this.handleTrackChange("audio", tracks?.activeAudio);
      this.handleTrackChange("text", tracks?.activeText);
    }
    try {
      if (this.instance !== undefined) {
        this.destroyPlayer(this.instance);
        window.player = undefined;
      }
    } catch (error) {
      // console.error(error);

      if (this.props.isConnected) {
        if (this.props.isLive) {
          //LINEAR initial load error
          sendAnalytics(
            this.props.dispatch,
            "ERROR",
            "50",
            "LINEAR",
            JSON.stringify({
              channelId: assetInfo.channel.id,
              reason: { httpCode: error },
            })
          );
        } else {
          //VOD initial load error
          sendAnalytics(
            this.props.dispatch,
            "ERROR",
            "1050",
            "VOD+CATCHUP",
            JSON.stringify({
              assetId: assetInfo.assetId,
              reason: { httpCode: error },
            })
          );
        }
      }
    }

    document.removeEventListener(
      "webkitfullscreenchange",
      this.handleFullscreenChange,
      false
    );
    document.removeEventListener(
      "mozfullscreenchange",
      this.handleFullscreenChange,
      false
    );
    document.removeEventListener(
      "msfullscreenchange",
      this.handleFullscreenChange,
      false
    );
    document.removeEventListener(
      "MSFullscreenChange",
      this.handleFullscreenChange,
      false
    ); //IE11
    document.removeEventListener(
      "fullscreenchange",
      this.handleFullscreenChange,
      false
    );
    document.removeEventListener(
      "fullscreenerror",
      this.handleFullscreenChange,
      false
    );
    document.removeEventListener("resize", this.handleFullscreenChange, false);
    document.removeEventListener(
      "orientationchange",
      this.handleFullscreenChange,
      false
    );
    document.addEventListener("keydown", this.handleKeyPressed, false);
  }

  async componentWillUnmount() {
    this.cleanUpEvents();
  }

  async handleclickonback() {
    if (this.timer) {
      await clearInterval(this.timer);
    }
    await this.instance.removeEventListener("status");

    // Exit the fullscreen mode
    if (
      document.fullscreen ||
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    if (this.props.assetInfo.isTVShowEpisode) {
      this.props.history.goBack();
    } else if (this.props.previousRoute) {
      this.props.history.goBack();
    } else {
      this.props.history.replace("/");
    }
  }

  setIsOverlayVisibleToFalse() {
    this.setState({ isOverlayVisible: false });
  }

  setOverlayTimer() {
    if (this.overlayTimer) {
      clearTimeout(this.overlayTimer);
    }
    this.overlayTimer = setTimeout(() => {
      if (this.state.isPlaying) {
        this.setState({ isOverlayVisible: false });
      }
    }, OVERLAY_DELAY);
  }

  render() {
    const {
      isOverlayVisible,
      isPlaying,
      progress,
      isFullscreen,
      isAdditionalContentVisible,
      volumeInfo,
      isBingeOverlayVisible,
      hasError,
      showLoader,
      isMuted,
      showSkipIntroButton,
    } = this.state;
    const {
      isConnected,
      liveEPG,
      channels,
      assetInfo,
      isLive,
      previousRoute,
      newPosition,
      width,
      height,
      timeshift,
      viewport,
      isTrailer,
      fromSlider,
      nextEpisode,
      menuOpen,
      activeEPG,
      t,
    } = this.props;

    // This is temporary
    if (hasError) {
      return (
        <PlayerErrorMessage
          closeAction={() => {
            this.props.history.replace(previousRoute ? previousRoute : "/");
          }}
        />
      );
    }
    const topRailHeigth = consts.topRailHeigth
      ? style[consts.topRailHeigth]
      : style.containerHeightC1;
    const events =
      viewport?.type === "DESKTOP"
        ? {
            onMouseMove: this.handleMouseMove.bind(this),
            onScroll: this.handleMouseMove.bind(this),
          }
        : { onClick: this.handleMouseMove.bind(this) };
    return (
      <div
        ref={this.playerContainer}
        className={
          fromSlider
            ? `${style.rootContainerSliderTrailer} ${topRailHeigth}`
            : isTrailer
            ? style.rootContainerTrailer
            : style.rootContainer
        }
        {...events}
        id="player"
      >
        <div
          style={{ overflow: "hidden" }}
          ref={this.simulateClick}
          onClick={() => {
            this.handleClickOnFullscreen();
          }}
        />
        {!isLive && isBingeOverlayVisible ? (
          <PlayerBingeOverlay
            asset={nextEpisode}
            onClickCloseIcon={this.handleCloseBingeWatching.bind(this)}
            goNextEpisode={this.goNextEpisode.bind(this)}
            isConnected={isConnected}
          />
        ) : (
          <>
            {isLive && !isTrailer && (
              <PlayerOverlayLive
                isConnected={isConnected}
                isVisible={isOverlayVisible || showLoader}
                isLive={isLive}
                width={width}
                height={height}
                liveEPG={liveEPG}
                channels={channels}
                isPlaying={isPlaying}
                viewport={viewport}
                timeshift={timeshift}
                isFullscreen={isFullscreen}
                newPosition={newPosition}
                handleclickonback={async () => {
                  if (this.timer) {
                    await clearInterval(this.timer);
                  }
                  await this.instance.removeEventListener("status");
                  redirectTo(this.props);
                  //this.props.history.replace(previousRoute ? previousRoute : "/");
                }}
                isAdditionalContentVisible={isAdditionalContentVisible}
                setIsOverlayVisibleToFalse={this.setIsOverlayVisibleToFalse.bind(
                  this
                )}
                onClickPlayPause={this.handleClickOnPlayPause.bind(this)}
                onClickSeekBackward={this.handleClickOnSeekBackward.bind(this)}
                onClickSeekForward={this.handleClickOnSeekForward.bind(this)}
                onClickFullscreen={this.handleClickOnFullscreen.bind(this)}
                onClickProgressBar={this.handleClickOnProgressBar.bind(this)}
                onClickProgressBarLive={this.handleClickOnProgressBarLive.bind(
                  this
                )}
                onClickRemote={this.handleClickOnRemote.bind(this)}
                handleCloseRemote={this.handleCloseRemote}
                onChangeVolume={this.handleVolumeChange.bind(this)}
                onChangeTrack={this.handleTrackChange.bind(this)}
                info={{
                  ...assetInfo,
                  ...progress,
                  volume: volumeInfo,
                  tracks: this.state.tracks,
                }}
                epg={activeEPG}
                showLoader={this.state.showLoader}
                config={{
                  canPause: this.canPause,
                  canSeekBackward: this.canSeekBackward,
                  canSeekForward: this.canSeekForward,
                  canSeekProgress: this.canSeekProgress,
                  canStartover: this.canStartover,
                  canRecord: this.canRecord,
                  audio: this.audio,
                  quality: this.quality,
                  canPictureInPicture: this.canPictureInPicture,
                }}
                subscriptionModal={this.subscriptionModal.bind(this)}
                showPauseIcon={this.state.showPauseIcon}
                t={t}
              />
            )}
            {!isLive && !isTrailer && (
              <PlayerOverlay
                isConnected={isConnected}
                isVisible={isOverlayVisible || showLoader}
                isLive={isLive}
                width={width}
                height={height}
                liveEPG={liveEPG}
                channels={channels}
                isPlaying={isPlaying}
                viewport={viewport}
                timeshift={timeshift}
                isFullscreen={isFullscreen}
                showLoader={this.state.showLoader}
                newPosition={newPosition}
                menuOpen={menuOpen}
                handleclickonback={this.handleclickonback}
                isAdditionalContentVisible={isAdditionalContentVisible}
                setIsOverlayVisibleToFalse={this.setIsOverlayVisibleToFalse.bind(
                  this
                )}
                onClickPlayPause={this.handleClickOnPlayPause.bind(this)}
                onClickSeekBackward={this.handleClickOnSeekBackward.bind(this)}
                onClickSeekForward={this.handleClickOnSeekForward.bind(this)}
                onClickFullscreen={this.handleClickOnFullscreen.bind(this)}
                onClickProgressBar={this.handleClickOnProgressBar.bind(this)}
                onClickProgressBarLive={this.handleClickOnProgressBarLive.bind(
                  this
                )}
                onClickRemote={this.handleClickOnRemote.bind(this)}
                handleCloseRemote={this.handleCloseRemote}
                onChangeVolume={this.handleVolumeChange.bind(this)}
                onChangeTrack={this.handleTrackChange.bind(this)}
                info={{
                  ...assetInfo,
                  ...progress,
                  volume: volumeInfo,
                  tracks: this.state.tracks,
                }}
                config={{
                  canPause: this.canPause,
                  canSeekBackward: this.canSeekBackward,
                  canSeekForward: this.canSeekForward,
                  canSeekProgress: this.canSeekProgress,
                  canStartover: this.canStartover,
                  canRecord: this.canRecord,
                  audio: this.audio,
                  quality: this.quality,
                  canPictureInPicture: this.canPictureInPicture,
                }}
                subscriptionModal={this.subscriptionModal.bind(this)}
                t={t}
                showSkipIntroButton={showSkipIntroButton}
                onSkipIntroClick={this.onSkipIntroClick}
              />
            )}
            {isTrailer && (
              <PlayerVolume
                rootClassName={fromSlider ? style.iconSlider : style.icon}
                isMuted={isMuted}
                onClick={(event) => this.switchVolume(event)}
              />
            )}
          </>
        )}
        <div
          ref={this.videoContainer}
          className={
            isTrailer && fromSlider ? style.playerTrailer : style.player
          }
          // eslint-disable-next-line react/no-unknown-property
          playsInline
        />
      </div>
    );
  }
}

Player.defaultProps = {
  isLive: false,
  url: "",
  liveEPG: [],
  channels: [],
  isConnected: false,
  assetInfo: {},
};

Player.propTypes = {
  isLive: PropTypes.bool,
  url: PropTypes.string,
  isConnected: PropTypes.bool,
  isTrailer: PropTypes.bool,
  fromSlider: PropTypes.bool,
  duration: PropTypes.any,
  assetInfo: PropTypes.object,
};

export default withRouter(
  connect((state, _props) => {
    return {
      menuOpen: state.ui.menuOpen,
      newPosition: state.ui.newPosition,
      profileToken: state.session.profileToken,
      subtitlePreference: state.ui.subtitlePreference,
      audioPreference: state.ui.audioPreference,
      audioPreferenceRef: state.ui.audioPreferenceRef,
      subtitlePreferenceRef: state.ui.subtitlePreferenceRef,
      audioLanguageRef: state.ui.audioLanguageRef,
      subtitleLanguageRef: state.ui.subtitleLanguageReff,
      isTrailerPlaying: state.player.trailer.playing,
      nextEpisode: TucanoSelectors.getAssetNextEpisode(state),
      accountStatus: state.account.user?.data?.status,
      assetTags: state.player.assetTags?.data,
      isAssetTagsLoading: state.player.assetTags?.loading,
    };
  })(Player)
);
